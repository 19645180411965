import React, { Fragment, useState, useEffect } from 'react'
import { useWalletContext } from 'hooks/walletContext'
import { Dialog, Transition } from '@headlessui/react';
import ConnectWalletModal from "components/ConnectWalletModal/ConnectWalletModal";
import { useAccount, useConnect, useSigner } from 'wagmi'
import { ethers } from "ethers";

import NFTCollectionVideo from 'video/NFTCollection.mp4'
import leftArrow from '../../images/leftArrow.svg'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import dollar from '../../images/Dollar.png'
import free from '../../images/SmallNftCards/free.png'
import Mask from 'images/Mask.png'
import CardNFT_img from 'images/CardNFT.png'
import cardNFT from 'video/CardNFT.mp4'
import Capsule01NFT from 'video/Capsule01NFT.mp4'
import Capsule02NFT from 'video/Capsule02NFT.mp4'
import PenthouseNFT from 'video/PenthouseNFT.mp4'
import { Link, useParams } from 'react-router-dom';
import './NFTDetail.scss'
import { alertError, alertSuccess, alertSuccessWithUrl } from 'utils/alerts';
import Counter from 'components/Collections/Counter';
import { CARD_NFT_CONTRACT_ABI, CARD_NFT_ADDRESS } from "_blockchain/contractsAbi.js";
const redirectUrl = `https://mumbai.polygonscan.com/tx/`;
const ETH_RPC_URL = "https://mainnet.infura.io/v3/c5c0f5d95169495295008417ac25dff5"
const smallCardArray = [
    {
        name: 'card_nft',
        video: cardNFT,
        title: 'Card NFT',
        description: 'NFT holders will be eligible for discount at top car rentals in Dubai and many more benefits are available',
        date: '29th Jan 2023',
        size: '10,950',
        price: '0',
        poster: CardNFT_img
    },
    // {
    //     name: 'vip_capsule',
    //     video: Capsule01NFT,
    //     title: 'VIP Capsule',
    //     description: '15 NFT Holders will be eligible for 2 days of rent free stay at the VIP Capsule in a yeat',
    //     size: '5,475',
    //     price: '10'
    // },
    // {
    //     name: 'luxury_capsule',
    //     video: Capsule02NFT,
    //     title: 'Luxury Capsule',
    //     description: '7 NFT Holders will be eligible for 1 days of rent free stay at the Luxury Capsule in a year',
    //     size: '780',
    //     price: '50'
    // },
    // {
    //     name: 'penthouse_capsule',
    //     video: PenthouseNFT,
    //     title: 'Penthouse Capsule',
    //     description: '4 NFT Holders will be eligible for 2 days of rent free stay at the Penthouse Capsule in a year',
    //     size: '48',
    //     price: '100'
    // }
]

const NFTDetail = () => {

    const { getCardNftContractInstance, getConnectAddress } = useWalletContext();
    const { isConnected,address } = useAccount();
    const { data: wagmiSigner } = useSigner();
    const [isShow, setIsShow] = useState(false)
    const [mintQty, setmintQty] = useState(1)
    const [urlParams, setUrlParams] = useState(useParams())
    const [title, setTitle] = useState(urlParams?.name.replace('-', '_'))
    // setTitle(title.replace('-','_'))

    const [nftAvailable, setNFTAvailable] = useState(false)
    const [freeNFT, setFreeNFT] = useState(false)
    const [Loading, setLoading] = useState(false)
    const [mintedCount, setMintedCount] = useState(0)
    const randomNFT = () => {

        if (smallCardArray.length) {
            smallCardArray.map((item) => {
                console.log(item.title, title)
                return (item?.name ? item.name == title ? setNFTAvailable(true) : '' : '')
            })
        }
    }
    const checkPrice = () => {

        if (smallCardArray.length) {
            smallCardArray.map((item) => {
                console.log(item.name, title)
                return (item?.name ? item?.name == title ? item?.price == 0 ? setFreeNFT(true) : '' : '' : '')
            })
        }
    }

    const toggleModal = () => {
        console.log("here")
        let t = !isShow;
        setIsShow(!isShow)
    }

    const [isCollectionSold, setisCollectionSold] = useState(false)
    const isMintingSoldOut = async () => {
        try {
            const tb = new ethers.Contract(CARD_NFT_ADDRESS, CARD_NFT_CONTRACT_ABI, ethers.getDefaultProvider('mainnet'))
            // const tb = await getCardNftContractInstance()
            const isSoldOut = await tb?.nftsAvailabelForFreeMint();
            console.log("isSoldOut.toString() ", isSoldOut.toString());
            let isSoldNum = isSoldOut.toString()
            let num = Number(isSoldNum.toString())
            if (num <= 0) {
                setisCollectionSold(true)
            }
            // return isSoldOut.toString();
        } catch (error) {
            console.log("ERROR", error);
            // return 0
        }
    }

    const getUserMintedNfts = async (mintQty) => {
        try {
            let addr = address
            const tb = await getCardNftContractInstance()
            const mintedRes = await tb?.balanceOf(addr);
            console.log("mintedRes",addr, mintedRes.toString());
            let noMint = Number(mintedRes.toString())
            if (noMint < 5) {
                return { isAvail: false, msg: noMint }
            } else if(noMint == 5) {
                return { isAvail: true, msg: `This ${addr} Has Already Minted 5 NFT's` }
            }else {
                return { isAvail: true, msg: `This ${addr} Has Already Minted ${5 - noMint} NFT's` }
            }
        } catch (error) {
            return { isAvail: true, msg: "Something Went Wrong." }
        }
    }

    const mintNft = async (second) => {
        setLoading(true);
        try {
            let resMintNo = await getUserMintedNfts(mintQty);
            if (resMintNo.isAvail) {
                alertError({ msg: resMintNo.msg })
                setLoading(false)
                return
            }
            if (mintQty === 0) {
                alertError({ msg: "Select At Least One" })
                setLoading(false)
                return
            }
            const tb = await getCardNftContractInstance()

            const mintRes = await tb?.mint(mintQty, {
                gasLimit: 170000
            });
            setLoading(false)
            console.log("mintRes :: ", mintRes);
            // if (mintRes.hash){

            // }
            alertSuccess({ msg: `You are owner of ${mintQty} NFT` })
            // getTrnxReciept(mintRes.hash)
            // alertSuccessWithUrl({msg: `You are owner of ${mintQty} NFT`,url:`${redirectUrl}${mintRes.hash}`})
            setmintQty(1)
        } catch (error) {
            setLoading(false)
            /* require(saleIsActive, "Public Sale in not open yet!");
                    require(totalSupply() + numberOfNFT <= max_nft_supply, "Purchase exceed max supply of NFTs");
                    require(balanceOf(msg.sender) + numberOfNFT <= max_nft_per_wallet, "Purchase exceeds max allowed per wallet");
                    require(totalSupply() + numberOfNFT <= max_nft_supply - maxReservedMints, "Not enough NFTs left to mint."); */
            if (error.message.indexOf('NETWORK_ERROR') != -1) {
                alertError({ msg: "Please Switch To ETH Mainnet" })
            }
            else if (error.message.indexOf("ACTION_REJECTED") != -1) {
                alertError({ msg: "User Rejected Transaction" })
            } else {
                let msg = error.message.split("error=")[1]?.split('"stack":')[0]?.split(',')[3] ?? "Something Went Wrong"
                alertError({ msg: msg })
            }
        }
    }

    const getTrnxReciept = async (txHash) => {
        try {
            const Provider = new ethers.providers.JsonRpcProvider(ETH_RPC_URL);
            const receiptObj = await Provider.getTransactionReceipt(txHash);
            console.log("getHashReceipt RES ::", receiptObj)
            if (receiptObj && Object.keys(receiptObj).length) {
                let status = receiptObj.status;
                if (status == 1) {

                } else {

                }
            } else {

            }
        } catch (error) {
            console.log("getTrnxReciept ::", error);
        } finally {
            setLoading(false)
        }
    }

    const increaseMintQty = () => {
        if (mintQty < 5 && mintQty > 0) setmintQty(mintQty + 1)
    }
    const decreaseMintQty = () => {
        if (mintQty > 1 && mintQty < 6) setmintQty(mintQty - 1)
    }

    const [isCounterFin, setisCounterFin] = useState(false)
    const isCounterDone = () => {
        /* show mint live */
        // remove counter
        setisCounterFin(true)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        isMintingSoldOut();
        // getTrnxReciept('0xdc917049aba2328c260891c0eb80eb3557a2c603c51cf0cffa8868d470680de8')
    }, [])

    return (
        <div className='container my-28'>
            <ConnectWalletToggel isShow={isShow} toggleModal={toggleModal} />
            <div id='nftDetails' className='flex flex-col md:flex-row flex-wrap justify-between '>
                <div className='md:w-[48%] w-full betweenmargin h-[500px] sm:h-[768px]' >
                    <div className='relative'>
                        <div className='absolute w-full h-auto sm:h-[768px] md:h-[503px] lg:!-top-[47px] xl:!top-0 lg:h-[602px] xl:h-[768px]  -left-[73px]' style={{ backgroundPosition: ' right 0 bottom 45%', backgroundImage: `url(${Mask})`, backgroundSize: 'cover', backgroundPosition: "center", backgroundRepeat: "no-repeat", }}></div>
                        {/* TO DO nft_clip left corner clip with border */}
                        <div id='videoBox' className={' absolute p-4 border border-[#E5BF00] sm:top-[94px] lg:top-[3px] xl:top-[94px] 2xl:top-[82px]'} style={{ backgroundColor: 'rgb(255 202 101 / 10%)', zIndex: '1' }}>
                            {true ?
                                smallCardArray.length && smallCardArray.map((item) => {
                                    return (item.name == title ?
                                        <video className="nft_clip cursor-pointer" playsInline muted loop autoPlay >
                                            <source src={item.video ? item.video : ''} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video> : '')
                                })
                                :
                                <video className="nft_clip cursor-pointer" playsInline muted loop autoPlay >
                                    <source src={smallCardArray[0].video ? smallCardArray[0].video : ''} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            }
                        </div>
                    </div>

                </div>

                <div className='md:w-[48%]  xl:my-auto   w-full text-left '>
                    <Link to={'/#our_collection'}>
                        <img className='relative mt-24 md:mt-0 left-[-10px] ' src={leftArrow} alt="left Arrow" />
                    </Link>
                    <h3 className='font-strasua text-base text-[#ADADAD] mb-[14px]'>Elchai car club</h3>
                    <h1 className='text-[26px] mb-[30px] text-white text-left'>{smallCardArray[0].title ? smallCardArray[0].title : ''}</h1>
                    <div className=' text-base mb-[30px] lg:h-[50px] lg:w-[468px]'>{smallCardArray[0].description ? smallCardArray[0].description : ''}</div>
                    {/* <div className=' text-base mb-[30px] h-[1px] w-full lg:w-[468px]' style={{ background: 'transparent radial-gradient(closest-side at 0% 50%, #E5BF00 0%, #000000 100%) 0% 0% no-repeat padding-box' ,opacity:1}}></div> */}
                    <div className="h-1 justify-center  items-center mb-[30px] visible " style={{ backgroundImage: ' linear-gradient(to right, #E5BF00, #000000 ) ', opacity: 1 }} >
                        {/* <div className="lg:-ml-[264px] 2xl:-ml-[188px] "><img src={bg1} /></div> */}
                    </div>
                    <div className='xl:mt-24 mt-5 flex flex-col mb-[40px]'>
                        <div className='flex justify-start items-center gap-3 mb-2'>
                            {isCollectionSold ?
                                <h3>Sold Out</h3>
                                : <h3>{isCounterFin ? "Free Mint Started" : 'Free Mint Will Start In'}</h3>}
                            {/* {smallCardArray[0].price ? smallCardArray[0].price == 0 ? <img src={free} alt="free" className='h-[22px] w-[22px]' /> : '' : ''} */}
                        </div>
                        <div className='flex justify-start gap-3'>
                            {/* <img className='w-[24px] h-[24px]' src={dollar} alt="dollar" />
                            <h3>{smallCardArray[0].price ? smallCardArray[0].price : '0'} USD</h3> */}
                        </div>
                    </div>
                    {isCollectionSold ? null : <>
                        {isCounterFin ? <>
                            {isConnected ?
                                <>
                                    <div className='flex flex-row gap-4 mb-4 items-center'>
                                        {mintQty == 1 ? <button type='button' className='w-10 h-10 text-2xl text-primary-900 border pb-1 border-[#E5BF00] rounded' onClick={decreaseMintQty} disabled>-</button> :
                                            <button type='button' className='w-10 h-10 text-2xl text-primary-900 border pb-1 border-[#E5BF00] rounded' onClick={decreaseMintQty} >-</button>}
                                        <span className='text-2xl'>{mintQty}</span>
                                        {mintQty == 5 ? <button type='button' className='w-10 h-10 text-2xl text-primary-900 border pb-1 border-[#E5BF00] rounded' onClick={increaseMintQty} disabled>+</button> :
                                            <button type='button' className='w-10 h-10 text-2xl text-primary-900 border pb-1 border-[#E5BF00] rounded' onClick={increaseMintQty}>+</button>}
                                    </div>
                                    <ButtonPrimary disabled={Loading} onClick={mintNft} loading={Loading} className="flex py-3 justify-center text-primary-900 items-center font-strasua sm:text-base text-sm sm:w-[136px] ">Buy NFT</ButtonPrimary>
                                </> :
                                <div>
                                    <ButtonPrimary onClick={() => toggleModal()} fontSize="test-base  font-medium text-[#FFFFFF]" sizeClass="px-11 py-2"> <span className="font-strasua">Connect Wallet</span></ButtonPrimary>
                                </div>
                            }
                        </> : <Counter isCounterDone={isCounterDone} />}
                    </>}

                    <ButtonPrimary onClick={() => window.open("/white_paper_card_nft.pdf", "_blank")} fontSize="test-base  font-medium text-[#FFFFFF]" sizeClass="px-11 py-2" className='mt-8'> <span className="font-strasua">White Paper</span></ButtonPrimary>
                </div>

            </div>
        </div>
    )
}

export default NFTDetail;


const ConnectWalletToggel = ({ isShow = false, toggleModal }) => {
    function closeModal() {
        toggleModal()
    }

    return (
        <>
            <Transition appear show={isShow} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl flex justify-center text-left align-middle shadow-xl transition-all">
                                    <ConnectWalletModal onClick={() => closeModal()} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}