import React, { useEffect } from 'react'
import ellipse from '../../images/icons/Ellipse.svg'

import "../PageTermsAndConditions/termsconditions.scss"
const PageTermsConditions = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <div className='nc-fill-container mt-[124px]   ' >
            <div className='container md:px-[15%]'>
                <div className='relative mx-auto w-full  mb-20' >

                    <div className='absolute xl:top-[-5rem] top-[-7rem] left-[1rem] lg:left-[10rem] 2xl:left-[16rem] h-[350px] w-full  mx-auto ' style={{ zIndex: '1', backgroundImage: `url(${ellipse})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", filter: 'blur(50px)' }} >

                    </div>
                    <h1 className=' xl:text-7xl sm:text-4xl lg:text-5xl text-2xl opacity-25 text-[#FFCA65] text-center lg:text-left'  >Terms and conditions</h1>
                    <h3 className='xl:text-4xl sm:text-2xl text-xl lg:text-3xl relative bottom-5 xl:bottom-10 text-[#E5BF00] text-center  xl:ml-[43px] 2xl:ml-[-10px]' style={{ zIndex: '1' }} >Terms and conditions</h3>

                </div>
                <div className=' lg:ml-[41px]   w-auto lg:w-[727px]  content_card_terms_conditions  ' >
                    {/* <div className='insidediv p-5   ' > */}
                    <span className="top-left-angle"></span>
                    <div className=' mx-[3%]  font-normal '>
                        <p className='text-base'>1. Terms</p>
                        <p className='text-sm mt-6 text-[#FFFFFFAD]' >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut</p>
                        <p className='text-sm mt-6 text-[#FFFFFFAD]'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores</p>
                        <p className='text-sm mt-6 text-[#FFFFFFAD]'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet</p>


                    </div>

                    {/* </div> */}

                </div>
                <div className='  lg:ml-[41px]   w-auto lg:w-[727px] border border-[#362B15]  content_card_terms_conditions mt-7 ' >
                    {/* <div className='insidediv p-5  ' > */}
                    <span className="top-left-angle"></span>
                    <div className=' mx-[3%]  font-normal '>
                        <p className='text-base'>2. Conditions</p>
                        <p className='text-sm mt-7 text-[#FFFFFFAD]' >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut</p>
                        <p className='text-sm mt-7 text-[#FFFFFFAD]'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores</p>
                        <p className='text-sm mt-7 text-[#FFFFFFAD]'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet</p>
                        <p className='text-sm mt-7 text-[#FFFFFFAD]'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores</p>
                        <p className='text-sm mt-7 text-[#FFFFFFAD]'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet</p>

                    </div>

                    {/* </div> */}

                </div>

            </div>



        </div>
    )
}

export default PageTermsConditions
