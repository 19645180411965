import React from "react";
import fountain from "images/fountain.png"
import footerUpperfirst from "images/footerUpperfirst.png"
import footerBottom from "images/footerBottom.svg"
import footerUpperImg from "images/footerUpperImg.svg"
import LinkedIn from "images/icons/linkedIn.svg"
import Twitter from "images/icons/twitter.svg"
import Discord from "images/icons/discord.svg"
import tiktok from "images/icons/tiktok.png";
import footerLineGlow from "images/footerLineGlow.svg"
import footerBG1 from "images/footerBG1.svg"
import ButtonPrimary from "shared/Button/ButtonPrimary";
import InvertButtonPrimary from "shared/Button/InvertButtonPrimary";
import './footer.scss'
import ellipse from 'images/bgtitle.png'
import { Link } from "react-router-dom";
const Footer = () => {
  let tsl = 'https://twitter.com/ElchaiCarNFT'
  let tiktokLink = 'https://www.tiktok.com/@elchaigroup'
  let discordLink = 'https://discord.gg/zNVwfuTExT'
  const socialLink = [

    {
      name: 'twitter',
      link: tsl,
      iconImage: Twitter
    },

    {
      name: 'discord',
      link: discordLink,
      iconImage: Discord
    },

    {
      name: 'Tik tok',
      link: tiktokLink,
      iconImage: tiktok
    },


  ]


  return (
    <div className=" flex flex-col justify-center text-center mt-40 py-20" >
      {/* <div className='container flex justify-center h-[400px]' style={{ background:'transparent radial-gradient(closest-side at 50% 50%, #E5BF00 0%, #281F10 65%, #000000 100%) 0% 0% no-repeat padding-box'}}></div> */}
      <div className='flex  justify-center place-items-center relative z-100 mb-[-3.5rem] 2xl:mb-[-5rem]' style={{ zIndex: '1' }}>
        <div className="absolute z-100 top-[-34px]" style={{ zIndex: '1' }}><img src={footerUpperfirst} alt="footerUpperfirst"></img></div>
        <div className="content_card_yellow_div">
          <div className='content_card_yellow flex flex-col justify-center border px-4 border-[#E5BF00] bg-primary-900'>
            <span className="top-left-angle"></span>
              <p className='font-strasua sm:text-xl text-base mt-12 text-neutral-900'>Explore more about our nfts collections on opensea</p>
            <div className='flex justify-center mt-6 mb-8'>
            <a href="https://opensea.io/collection/elchai-card-nft" target={'_blank'}>
              <InvertButtonPrimary className="flex sm:px-6 px-3 py-3 text-xs sm:text-base justify-center text-neutral-900 items-center font-strasua sm:text-base text-sm sm:w-[216px] ">Explore on opensea</InvertButtonPrimary>
            </a>
            </div>
          </div>
        </div>

      </div>
      <div className='relative flex  justify-center '>
        <div className="content_card_footer" style={{ border: '1px solid #E5BF00' }}>
          <span className="top-left-angle"></span>
          <span className="top-right-angle"></span>
          {/* <div className=' insideFooter flex justify-center place-items-center   '> */}
          <div className='container flex justify-center py-12'>
            <div>
              <div className='flex justify-center sm:mt-7 mt-3'>
                <img src={fountain} alt="fountain"></img>
              </div>
              <p className='text-primary-900 mt-2 lg:text-5xl lg:leading-[55px] font-strasua text-4xl'>Elchai car club</p>
              <div className='flex justify-center mt-9'>
                <div className='flex flex-wrap sm:flex-row flex-col font-Helvetica justify-between sm:w-[26rem] gap-2'>
                  <Link to={'/'} ><span className='text-sm'>Home</span></Link>
                  <a href='/#about-us'><span className='text-sm'>About us</span></a>
                  <Link to={'/nft/card-nft'} ><span className='text-sm'>Mint</span></Link>
                  <a href="/#nc-roadmap"><span className='text-sm'>Roadmap</span></a>
                  <Link to={'/team'} ><span className='text-sm'>Team</span></Link>
                  <a href="/#faq"><span className='text-sm'>FAQ</span></a>
                </div>
              </div>
              <div className='flex justify-center sm:block hidden place-items-center 2xl:mt-12 md:mt-10 mt-8'>
                <div className="h-1 lg:w-[53.9375rem] w-[100%] flex justify-center items-center " style={{ background: ' transparent radial-gradient(closest-side at 50% 50%, #E5BF00 0%, #000000 100%) 0% 0% no-repeat padding-box', opacity: 1 }} ><img src={footerLineGlow} /></div>
              </div>
              <div className='flex justify-center 2xl:mt-12 md:mt-10 mt-8'>
                <div className='flex justify-between w-[8.625rem]'>
                  {
                    socialLink.map(item => {
                      return (
                          <a href={item.link} target="_blank" rel="noopener noreferrer" className="w-[20px] h-[20px] max-h-[20px]">
                            <img className="w-[16px] h-[16px] max-h-[20px]" src={item.iconImage} alt={item.name} />
                          </a>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className='absolute  top-[-5rem] left-[2rem] lg:left-[7rem] 2xl:left-[7rem] h-[220px] w-full max-w-[80vw] mx-auto' style={{ backgroundImage: `url(${ellipse})`, backgroundSize: 'cover', backgroundPosition: "center", backgroundRepeat: "no-repeat", filter: 'blur(50px)' }} >
        </div>
        <div className='absolute  bottom-[-5rem] left-[2rem] sm:left-[5rem] lg:left-[7rem] 2xl:left-[8rem] h-[101px] w-full max-w-[80vw] mx-auto' style={{ backgroundImage: `url(${ellipse})`, backgroundSize: 'cover', backgroundPosition: "center", backgroundRepeat: "no-repeat", filter: 'blur(50px)' }} >
        </div>
      </div>
      {/* <div className='container flex justify-center h-[400px]' style={{ background:'transparent radial-gradient(closest-side at 50% 50%, #E5BF00 0%, #281F10 65%, #000000 100%) 0% 0% no-repeat padding-box'}}></div> */}
      {/* <div className=''>
        <img src={footerBottom} alt='footerBottom'></img>
      </div> */}
    </div>

  );
};
export default Footer;
