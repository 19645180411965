import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import MyRouter from "routers/index";
import { ContractProvider } from "hooks/contractContext";
import { ToastContainer } from "react-toastify";

function App() {

    useEffect(() => {
        const root = document.querySelector("html");
        if (!root) return;
        !root.classList.contains("dark") && root.classList.add("dark");
        localStorage.theme = "dark";
    }, []);

    return (
        <div className="bg-neutral-900 text-base dark:bg-neutral-900 text-white">
            <ToastContainer position="top-right" autoClose={3000} theme="dark" closeOnClick pauseOnFocusLoss />
            <BrowserRouter basename="/">
                
                <ContractProvider>
                    <MyRouter />
                </ContractProvider>
            </BrowserRouter>

        </div>
    );
}

export default App;
