import Button from "shared/Button/Button";
import React from "react";
import CredPrimaryBtn from "./credPrimaryButton";

const ButtonPrimary = ({
  className = "",
  ...args
}) => {
  return (
    <CredPrimaryBtn
      className={`disabled:bg-opacity-70 ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimary;
