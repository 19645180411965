import React, { Fragment, useState, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Navigation from "shared/Navigation/Navigation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react';
import ConnectWalletModal from "components/ConnectWalletModal/ConnectWalletModal";
import { alertError, alertInfo } from "utils/alerts";
import { useAccount, useDisconnect } from 'wagmi'
import ButtonSecondary from "shared/Button/ButtonSecondary";
import discord from "images/icons/discord1.png";
import linkdin from "images/icons/linkdin1.png";
import twitter from "images/icons/twitter1.png";
import tiktok from "images/icons/tiktok.png";

import bg1 from "images/icons/line.png";
import instagram from "images/icons/instagram.svg";
const MainNav2Logged = () => {
  const { user } = useSelector(state => state)
  const dispatch = useDispatch()
  const history = useHistory()
  const [account, setAccount] = useState();
  const [error, setError] = useState("");
  const [isShow, setIsShow] = useState(false)



  const { connector: activeConnector, isConnected, address, isDisconnected, status } = useAccount()
  const { disconnect } = useDisconnect()

  const disconnectWallet = async () => {
    disconnect();
    history.replace("/")
  };


  const toggleModal = () => {
    console.log("here")
    let t = !isShow;
    setIsShow(!isShow)
  }
  let tsl ='https://twitter.com/ElchaiCarNFT'
  let tiktokLink = 'https://www.tiktok.com/@elchaigroup'
  let discordLink = 'https://discord.gg/zNVwfuTExT'
  const socialLink = [

    {
      name: 'twitter',
      link: tsl,
      iconImage: twitter
    },

    {
      name: 'discord',
      link: discordLink,
      iconImage: discord
    },

    {
      name: 'Tik tok',
      link: tiktokLink,
      iconImage: tiktok
    },


  ]
  return (
    <>
      <div className={`nc-MainNav2Logged bg-[#050505] relative z-10 ${"onTop "}`}  >
        <div className="text-center flex flex-row justify-center w-full absolute  h-[54px]" style={{zIndex: '1' , filter:`blur(34px)`,background: 'transparent radial-gradient(closest-side at 50% 50%, #E5BF00 0%, #281F10 65%, #000000 100%) 0% 0% no-repeat padding-box' , mixBlendMode: 'screen',opacity: 1 }}>&nbsp;</div>
        <div className="cursor-pointer flex flex-row gap-[3rem] justify-center "  >
          {
            socialLink.map(item => {
              return (
                <div className="mt-4"  style={{zIndex: '1' }}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer" className="w-[20px] h-[20px] max-h-[20px]">
                    <img className="w-[16px] h-[16px] max-h-[20px]" src={item.iconImage} alt={item.name} />
                  </a>
                </div>
              )
            })
          }
        </div>
        <div className="container  py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
          <ConnectWalletToggel isShow={isShow} toggleModal={toggleModal} />
          <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-0">
            <Logo />
            <div className="flex flex-col">
              <div className="text-[25px] font-strasua text-[#FFFFFF]">Elchai</div>
              <div className="text-base font-strasua text-[#FFFFFF] ">car club</div>

            </div>
            {/*  <span className="text-2xl font-strasua text-[#FFFFFF]">Elchai <br></br><span className="text-base">car club</span></span> */}
          </div>
          {/*   <div className="flex justify-start  ">
        <h1>Elchai <br></br>car club</h1>
        </div>  */}
          <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
            <div className="hidden items-center lg:flex space-x-2 font-strasua">
              <Navigation />
              {/* <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-6000"></div> */}
              {/* <div className="flex">
              <NotifyDropdown />
            </div> */}
              <div></div>
              {/* {
              user.id
              ? <AvatarDropdown /> 
              : <ButtonPrimary href={"/login"} fontSize="test-sm font-medium" sizeClass="px-11 py-2">Login</ButtonPrimary>
            } */}
              {
                isConnected ?
                    <ButtonPrimary onClick={() => disconnectWallet()} fontSize="test-base font-medium text-[#FFFFFF]" sizeClass="px-11 py-2">Disconnect</ButtonPrimary >
                  : <ButtonPrimary onClick={() => toggleModal()} fontSize="test-base  font-medium text-[#FFFFFF]" sizeClass="px-11 py-2"> <span className="font-strasua">Connect Wallet</span></ButtonPrimary>
              }
            </div>
            <div className="flex items-center space-x-3 lg:hidden">
              {/* <NotifyDropdown /> */}
              {
                isConnected
                  ? <AvatarDropdown />
                  : null
              }
              {/* <AvatarDropdown /> */}
              <MenuBar />
            </div>
          </div>
        </div>
        <div className="h-1 justify-center  items-center lg:-mt-[18px] invisible lg:visible " style={{ background: ' transparent radial-gradient(closest-side at 50% 50%, #E5BF00 0%, #000000 100%) 0% 0% no-repeat padding-box', opacity: 1 }} >
          <div className="lg:-ml-[264px] 2xl:-ml-[188px] "><img src={bg1} /></div>
        </div>
      </div>


    </>


  );
};

export const truncateAddress = (address, connectWallet) => {
  if (!address) {

    return (

      <ButtonPrimary onClick={() => connectWallet()} fontSize="test-sm font-medium" sizeClass="px-11 py-2"><span className="font-strasua">Connect Wallet</span></ButtonPrimary>
    )
  }
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

const ConnectWalletToggel = ({ isShow = false, toggleModal }) => {
  function closeModal() {
    toggleModal()
  }

  return (
    <>
      <Transition appear show={isShow} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl flex justify-center text-left align-middle shadow-xl transition-all">
                  <ConnectWalletModal onClick={() => closeModal()} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default MainNav2Logged;
