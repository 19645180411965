import React from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import myVideo from '../../video/elchaiBannerVideo.mp4'
import bgFire from '../../video/bgFire.mp4'
import './elchaiCarclub.scss'
import { Link, useHistory } from 'react-router-dom'
import HomeCounter from './homeCounter'

const ElchaiCarClub = () => {

    const history = useHistory()

    const redirectToMint = (params) => {
        history.push('/nft/card-nft')
    }

     return (
        <>
            <div className='relative'>
                <div className=' container relative z-10  my-3   flex justify-between flex-col lg:flex-row items-center md:items-start gap-5 lg:gap-0 sm:gap-3'>
                    <div className='flex flex-col lg:my-auto w-full justify-center items-center lg:justify-start lg:items-start'>
                        <h1 className='text-[#E5BF00] lg:text-6xl text-4xl  font-bold uppercase text-center lg:text-start '>MINT NFTs and RIDE</h1>
                        {/* <h1 className='text-[#E5BF00] lg:text-6xl text-4xl  font-bold uppercase break-keep text-center lg:text-start '>Elchai car club</h1> */}
                        <p className='mt-5  md:max-w-[404px] text-center lg:text-start'>Get ready for the ultimate adrenaline rush!</p>
                        <p className=' mb-5 md:max-w-[404px] text-center lg:text-start'>Mint our NFT and drive the supercar of your dreams from the Elchai Car Club.</p>
                        <div className='mb-4 w-full uppercase flex justify-center lg:justify-start '>
                            <ButtonPrimary onClick={redirectToMint} className="flex py-3 justify-center text-primary-900 items-center font-strasua sm:text-base text-sm sm:w-[140px] ">Mint Nft</ButtonPrimary >
                        </div>
                        <div className=''>
                            <HomeCounter />
                        </div>
                    </div>
                                        
                    <video id='car_logos' className="lg:w-[527px] lg:h-[527px]  " playsInline muted loop autoPlay >
                        <source src={myVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                 
                </div>
                <video autoplay playsInline loop muted id='myVideo' className={`vid_player`} /* poster={currentVersion.image} */ src={bgFire} />
            </div>

        </>
    )
}

export default ElchaiCarClub