import React from "react";
import MainNav2 from "./MainNav2";

const Header2 = () => {
  return (
    <div className="nc-Header2 bg-website-220 relative w-full z-40 ">
      <MainNav2 />
    </div>
  );
};

export default Header2;
