import Button from "shared/Button/Button";
import React from "react";

const ButtonSecondary = ({
  className = " ",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonSecondary  border border-primary-900 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-600 hover:bg-neutral-100 dark:hover:bg-primary-900 dark:hover:text-black ${className}`}
      {...args}
    />
  );
};

export default ButtonSecondary;
