import React from "react";
import {  Switch, Route, useLocation, useHistory, Redirect } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import SiteHeader from "containers/SiteHeader";
import Page404 from "containers/Page404/Page404";
import HomePage from "containers/Home/HomePage";
import Sidebar from 'components/Sidebar/Sidebar'
import { useSelector } from "react-redux";
import {useAccount} from 'wagmi'
import Team from "containers/PageTeam/Team";
import NFTDetail from "containers/NFTDetail/NFTDetail";
import PageTermsConditions from "containers/PageTermsAndConditions/PageTermsConditions";

export const pages = [
  { path: "/", exact: true, component: HomePage },
  // { path: "/team", exact: true, component: Team },
  { path: "/terms-and-conditions", exact: true, component: PageTermsConditions },
  { path: "/nft/:name", exact: true, component: NFTDetail },

];

const Routes = () => {
  const {user} = useSelector(state=>state)
  const { connector: activeConnector, isConnected, address, isDisconnected, status } = useAccount()
  
  let location = useLocation();
  const history = useHistory()
  const isDashboardRoute = location.pathname.toLowerCase().includes('dashboard')
  // console.log("from start",location,isDashboardRoute)
  const loginSignUpRoute = location.pathname.toLowerCase().includes('login') || location.pathname.toLowerCase().includes('signup')

  // if(isDashboardRoute){
  //   return (
  //     <div className="flex flex-row min-h-screen">
  //       <ScrollToTop />
  //         {/* <Sidebar/> */}
  //         <div className="flex-1">
  //           <Switch>
  //             {pages.map(({ component, path, exact }) => {
  //               if(!isConnected) return <Redirect to="/" />
  //               return (
  //                 <Route
  //                   key={path}
  //                   component={component}
  //                   exact={!!exact}
  //                   path={path}
  //                 />
  //               );
  //             })}
  //             <Route component={Page404} />
  //           </Switch>
  //       </div>
  //     </div>
  //   );
  // } else {
    return (
      <>
       <SiteHeader />
        <main>
          <Switch>
            {pages.map(({ component, path, exact }) => {
              return (
                <Route
                  key={path}
                  component={component}
                  exact={!!exact}
                  path={path}
                />
              );
            })}
            <Route component={Page404} />
          </Switch>
        </main>
        <Footer />
      </>)
  }

// };

const DashboardLayout = () => {
  return <div className="flex flex-row">
      <Sidebar />
      <div className="flex-1">
        <SiteHeader />
          <Switch>
            {pages.map(({ component, path, exact }) => {
              return (
                <Route
                  key={path}
                  component={component}
                  exact={!!exact}
                  path={path}
                />
              );
            })}
            <Route component={Page404} />
          </Switch>
        <Footer />
    </div>
  </div>
}

const WebsiteLayout = () => {
  return (
    <>
      <SiteHeader />
      <main>
        <Switch>
          {pages.map(({ component, path, exact }) => {
            return (
              <Route
                key={path}
                component={component}
                exact={!!exact}
                path={path}
              />
            );
          })}
          <Route component={Page404} />
        </Switch>
      </main>
      <Footer />
    </>
  )
}
export default Routes;
