import React, { useState, useEffect } from "react";

const Counter = ({ isCounterDone }) => {
    const [days, setDays] = useState("");
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const [isDone, setisDone] = useState(false)
    let currentTimeStamp = new Date('Thu Jan 29 2023 18:29:00 GMT+0530 (India Standard Time)')
    // console.log("currentTimeStamp :",currentTimeStamp)
    let utc = currentTimeStamp.getTime() + (currentTimeStamp.getTimezoneOffset() * 60000);
    let dubaiTime = new Date(utc + (3600000 * "+4"));
    // console.log("The local time in dubai is " + dubaiTime);
    var deadline = currentTimeStamp.getTime();

    useEffect(() => {
        var now = new Date().getTime();
        var t = deadline - now;
        if (t < 0) {
            setisDone(true)
            isCounterDone()
            clearInterval(x)
            return
        }
        var x = setInterval(function () {
            var now = new Date().getTime();
            var t = deadline - now;
            //   console.log("deadline - now ::",deadline - now);
            let dt = Math.floor(t / (1000 * 60 * 60 * 24));
            if (dt == 0) {
                dt = "00"
            } else if (dt < 10) {
                dt = "0" + dt;
            } else {
                dt = dt.toString()
            }
            setDays(dt);

            let hrs = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            if (hrs == 0) {
                hrs = "00"
            } else if (hrs < 10) {
                hrs = "0" + hrs;
            } else {
                hrs = hrs.toString()
            }

            setHours(hrs);

            let mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
            if (mins == 0) {
                mins = "00"
            } else if (mins < 10) {
                mins = "0" + mins;
            } else {
                mins = mins.toString()
            }
            setMinutes(mins);

            let sec = Math.floor((t % (1000 * 60)) / 1000);
            if (sec == 0) {
                sec = "00"
            } else if (sec < 10) {
                sec = "0" + sec;
            } else {
                sec = sec.toString()
            }
            setSeconds(sec);
            // document.getElementById("demo").innerHTML = days + "d "
            //     + hours + "h " + minutes + "m " + seconds + "s ";
            if (t < 0) {
                clearInterval(x);
                // document.getElementById("demo").innerHTML = "EXPIRED";
            }
        }, 1000);
        return () => {
            clearInterval(x)
        }
    }, [seconds])

    if (isDone) {
        return null
    }

    return (
        <div className="flex justify-start mt-2  bg-[#050505] relative " >
            <div className="flex md:gap-x-2 gap-x-2 justify-center">
                <div>
                    <div className="text-xs md:text-sm flex justify-center  font-bold text-primary-900">{days || '-'}</div>
                    <div className="text-xs md:text-sm text-center mt-0.5 text-[#b5b5b5]">Days</div>
                </div>
                <div className=" text-[#b5b5b5]">:</div>
                <div>
                    <div className="text-xs md:text-sm flex justify-center  font-bold text-primary-900">{hours || '-'}</div>
                    <div className="text-xs md:text-sm text-center mt-0.5 text-[#b5b5b5]">Hours</div>
                </div>
                <div className=" text-[#b5b5b5]">:</div>
                <div>
                    <div className="text-xs md:text-sm flex justify-center  font-bold text-primary-900">{minutes || '-'}</div>
                    <div className="text-xs md:text-sm text-center mt-0.5 text-[#b5b5b5]">Minute</div>
                </div>
                <div className=" text-[#b5b5b5]">:</div>
                <div>
                    <div className="text-xs md:text-sm flex justify-center  font-bold text-primary-900">{seconds || '-'}</div>
                    <div className="text-xs md:text-sm text-center mt-0.5 text-[#b5b5b5]">Second</div>
                </div>
            </div>
        </div>
    );
}

export default Counter;
