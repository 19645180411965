import React,{ createContext, useContext, useEffect, useState } from "react";

const contractContext = createContext();

export const useContractContext = () => useContext(contractContext);

export const ContractProvider = ({ children }) => {
  const [data, setData] = useState("contract");


  return (
    <contractContext.Provider value={{data}}>
      {children}
    </contractContext.Provider>
  );
};
