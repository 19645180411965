import React, { useState } from "react";
import "./roadmap.scss";
import cardBg from "images/card-background-roadmap.svg"
import MainTitleHeader from "components/mainHeaderTitle";
const RoadMapComp = (params) => {

    const [roadmapCardList, setroadmapCardList] = useState([
        {}
    ])

    return (
        <div id="nc-roadmap" className="mt-48">
            <div className="mb-10">
                <MainTitleHeader title={'Roadmap'} position={'right'} />
            </div>
            <div>
                <div className="container_rd">
                    <div className="timeline-block timeline-block-left">
                        <div className="marker"></div>
                        <div className=" timeline-content p-[15px] md:pl-0 pr-0 md:pr-[15px] pl-[15px]">
                            <RoadMapCard  subtitle={`Community Formation`} title={`Phase 1: READY, SET, GROW!`} description={'Elchai Car Club is formed for supercar and luxury car enthusiasts. And we’re extending the exclusive car membership from Dubai to the rest of the world. We will grow the club online via our social media channels and community on Discord.'} />
                        </div>
                    </div>

                    <div className="timeline-block timeline-block-right">
                        <div className="marker_right"></div>
                        <div className="timeline-content  p-[15px] pr-0 !sm:pr-[15px]">
                            <RoadMapCard subtitle={`First NFT Collection Launch`} title={`Phase 2: STARTING THE ENGINE WITH AN NFT LAUNCH`} description={'We’re kicking off with the first NFT collection in the first quarter of 2023. These NFTs will be the key to grant the community access to luxury rides, supercars and hypercars around the futuristic city of Dubai.'} />
                        </div>
                    </div>

                    <div className="timeline-block timeline-block-left">
                        <div className="marker"></div>
                        <div className="timeline-content  p-[15px] md:pl-0 pr-0 md:pr-[15px] pl-[15px]">
                            <RoadMapCard subtitle={`Launch VIP Capsule NFTs`} title={`Phase 3: DRIVING TO A VIP REAL ESTATE`}  description={'From limited-edition supercars, hypercars, to luxury living. Elchai Group will mint a more standard collection that will provide keys for a 150 sqm VIP apartment at The Club to be constructed initially in Dubai.'} />
                        </div>
                    </div>

                    <div className="timeline-block timeline-block-right">
                        <div className="marker_right"></div>
                        <div className="timeline-content  p-[15px] pr-0 !sm:pr-[15px]">
                            <RoadMapCard subtitle={`Launch Luxury Capsule NFTs`} title={`Phase 4: REVVING UP TO LUXURY REAL ESTATE`}   description={'Elchai will launch a semi-limited NFT collection that will open the doors of a 300 sqm luxury property at the club. It is double the space with added amenities and a huge upgrade from the VIP capsule.'}/>
                        </div>
                    </div>

                    <div className="timeline-block timeline-block-left">
                        <div className="marker"></div>
                        <div className="timeline-content  p-[15px] md:pl-0 pr-0 md:pr-[15px] pl-[15px]">
                            <RoadMapCard subtitle={`Launch the Penthouse Capsule NFTs`} title={`Phase 5: HIGH SPEED TO THE PENTHOUSE`}  description={'For those who want the best, Elchai offers them the top! The 600 mq2 Penthouse capsule will be the most expensive space in the club. This space will be exclusive to the penthouse NFT holders.'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoadMapComp;

const RoadMapCard = ({ title = 'phase 1',subtitle="" ,description}) => {
    return (

        <div className="content_card max-w-[364px] sm:h-[420px] p-4 pt-[2rem] pb-5 sm:p-6 hover:border-4 border border-[#E5BF00]">
            <span className="top-left-angle"></span>
            <span className="bottom-left-angle"></span>
            <div className="flex flex-col justify-around h-full ">
                <div className="title ">
                    <span className="block text-base text-primary-900">{title}</span>
                    <h2 className="text-lg sm:text-xl lg:text-2xl mt-4 text-white">{subtitle}</h2>
                </div>
                <div className="divider border border-t-primary-900 w-[80%] md:w-[70%] my-5 lg:my-0 "></div>
                <div className="para flex-grow-3 text-base text-white"><p>{description}</p></div>
            </div>
        </div>
    )
}