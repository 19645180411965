import React, { useState } from 'react'
import './Faqs.scss'
import MainTitleHeader from 'components/mainHeaderTitle'
import footerLineGlow from "images/footerLineGlow.svg"

const Faqs = () => {
    const [Faq1, setFaq1] = useState(true)
    const [Faq2, setFaq2] = useState(false)
    const [Faq3, setFaq3] = useState(false)
    const [Faq4, setFaq4] = useState(false)
    const expand = (faqId, headingId, descId)=>{
        if(faqId == '#faq1'){
            setFaq2(false)
            setFaq3(false)
            setFaq4(false)
        } else if(faqId == '#faq2'){            
            setFaq1(false)
            setFaq3(false)
            setFaq4(false)
        } else if(faqId == '#faq3'){            
            setFaq1(false)
            setFaq2(false)
            setFaq4(false)
        } else if(faqId == '#faq4'){            
            setFaq1(false)
            setFaq2(false)
            setFaq3(false)
        }
        let FAQid = document.querySelector(faqId)
        let Headingid = document.querySelector(headingId)
        let Descid = document.querySelector(descId)

    }
    const collapse = (faqId, headingId, descId)=>{
        let FAQid = document.querySelector(faqId)
        let Headingid = document.querySelector(headingId)
        let Descid = document.querySelector(descId)

    }
    return (
        <div className='flex flex-col justify-center' id='faq'>
            <div className='mt-40'>
                <MainTitleHeader title={'faq'} position={'left'} />
            </div>
            <div className='flex justify-center'>
                <div className='w-[90vw] md:w-[46.875rem] px-4'>
                    <div id='faq1' className={` ${Faq1 ? 'content_card_faq_div':''}`}>
                        <div id='faq1Heading' className={`flex content_card_faq justify-between items-center  py-5 sm:py-1 pl-7 pr-2 border border-[#E5BF00] ${Faq1 ? 'border-b-0' : ''}`}>
                            <span className="top-left-angle"></span>
                            <p className='absolute  font-strasua text-lg sm:text-xl'>What is elchai car club?</p>
                            <div className='relative w-full flex justify-end top-[-52px] sm:top-[-36px]'>
                                {Faq1 ? 
                                <button onClick={()=>{
                                    setFaq1(false)
                                    collapse('#faq1', '#faq1Heading', '#faq1Desc')}}
                                    className='border bg-website-500 text-primary-900 px-4 pb-1 text-3xl border-[#E5BF00]'><span>-</span></button>
                                :
                                <button onClick={()=>{
                                    setFaq1(true)
                                    expand('#faq1', '#faq1Heading', '#faq1Desc')}}
                                    className='border bg-website-500 text-primary-900 px-3 pb-1 text-3xl border-[#E5BF00]'><span>+</span></button>}
                            </div>
                        </div>
                        <div id='faq1Desc' className={`border p-7 pt-0 text-base border-[#E5BF00] border-t-0 ${Faq1 ? 'block' : 'hidden'}`}>
                            <div className='flex justify-start pt-2'>
                                <div className="h-1 md:w-[40%] w-[60%] flex justify-start items-center relative " style={{ background: ' transparent radial-gradient(closest-side at 50% 50%, #E5BF00 0%, #000000 100%) 0% 0% no-repeat padding-box', opacity: 1 }} ><img className='absolute left-6 h-[6px]' src={footerLineGlow} /></div>
                            </div>
                            <p className='pt-7'>Elchai Car Club is a long-standing membership club for supercar and hypercar owners and enthusiasts. They are bringing their love for cars in the metaverse too! With the use of NFTs they are extending exclusive membership to the blockchain community.</p>
                        </div>
                    </div>
                    <div id='faq2' className={` ${Faq2 ? 'content_card_faq_div':''} mt-14`}>
                        <div id='faq2Heading' className={`flex content_card_faq  justify-between items-center border py-5 sm:py-1 pl-7 pr-2 border-[#E5BF00] ${Faq2 ? 'border-b-0' : ''}`}>
                            <span className="top-left-angle"></span>
                            <p className='absolute  font-strasua text-lg sm:text-xl'>Why Invest in Elchai Car Club?</p>
                            <div className='relative w-full flex justify-end top-[-52px] sm:top-[-36px]'>
                                {Faq2 ? 
                                <button onClick={()=>{
                                    setFaq2(false)
                                    collapse('#faq2', '#faq2Heading', '#faq2Desc')}}
                                    className='border bg-website-500 text-primary-900 px-4 pb-1 text-3xl border-[#E5BF00]'><span>-</span></button>
                                :
                                <button onClick={()=>{
                                    setFaq2(true)
                                    expand('#faq2', '#faq2Heading', '#faq2Desc')}}
                                    className='border bg-website-500 text-primary-900 px-3 pb-1 text-3xl border-[#E5BF00]'><span>+</span></button>}
                            </div>
                        </div>
                        <div id='faq2Desc' className={`border p-7 pt-0 text-base border-[#E5BF00] border-t-0 ${Faq2 ? 'block' : 'hidden'}`}>
                            <div className='flex justify-start pt-2'>
                                <div className="h-1 md:w-[40%] w-[60%] flex justify-start items-center relative " style={{ background: ' transparent radial-gradient(closest-side at 50% 50%, #E5BF00 0%, #000000 100%) 0% 0% no-repeat padding-box', opacity: 1 }} ><img className='absolute left-6 h-[6px]' src={footerLineGlow} /></div>
                            </div>
                            <p className='pt-7'>Elchai Car Club NFTs is more than just an art. It serves as an exclusive access to high living experiences. It is an NFT investment with real-world use cases.</p>
                        </div>
                    </div>
                    <div id='faq3' className={` ${Faq3 ? 'content_card_faq_div':''} mt-14`}>
                        <div id='faq3Heading' className={`flex content_card_faq  justify-between items-center border py-5 sm:py-1 pl-7 pr-2 border-[#E5BF00] ${Faq3 ? 'border-b-0' : ''}`}>
                            <span className="top-left-angle"></span>
                            <p className='absolute  font-strasua text-lg sm:text-xl'>How do I buy an Elchai Car Club NFT?</p>
                            <div className='relative w-full flex justify-end top-[-52px] sm:top-[-36px]'>
                                {Faq3 ? 
                                <button onClick={()=>{
                                    setFaq3(false)
                                    collapse('#faq3', '#faq3Heading', '#faq3Desc')}}
                                    className='border bg-website-500 text-primary-900 px-4 pb-1 text-3xl border-[#E5BF00]'><span>-</span></button>
                                :
                                <button onClick={()=>{
                                    setFaq3(true)
                                    expand('#faq3', '#faq3Heading', '#faq3Desc')}}
                                    className='border bg-website-500 text-primary-900 px-3 pb-1 text-3xl border-[#E5BF00]'><span>+</span></button>}
                            </div>
                        </div>
                        <div id='faq3Desc' className={`border p-7 pt-0 text-base border-[#E5BF00] border-t-0 ${Faq3 ? 'block' : 'hidden'}`}>
                            <div className='flex justify-start pt-2'>
                                <div className="h-1 md:w-[40%] w-[60%] flex justify-start items-center relative " style={{ background: ' transparent radial-gradient(closest-side at 50% 50%, #E5BF00 0%, #000000 100%) 0% 0% no-repeat padding-box', opacity: 1 }} ><img className='absolute left-6 h-[6px]' src={footerLineGlow} /></div>
                            </div>
                            <p className='pt-7'>You have to own an ETH-friendly crypto wallet in order to mint Elchai Car Club NFTs. You will need to connect your wallet such as TrustWallet and Metamask to be able to buy one on OpenSea.</p>
                        </div>
                    </div>
                    <div id='faq4' className={` ${Faq4 ? 'content_card_faq_div':''} mt-14`}>
                        <div id='faq4Heading' className={`flex content_card_faq  justify-between items-center border py-5 sm:py-1 pl-7 pr-2 border-[#E5BF00] ${Faq4 ? 'border-b-0' : ''}`}>
                            <span className="top-left-angle"></span>
                            <p className='absolute  font-strasua text-lg sm:text-xl'>How limited is Elchai Car Club NFTs?</p>
                            <div className='relative w-full flex justify-end top-[-52px] sm:top-[-36px]'>
                                {Faq4 ? 
                                <button onClick={()=>{
                                    setFaq4(false)
                                    collapse('#faq4', '#faq4Heading', '#faq4Desc')}}
                                    className='border bg-website-500 text-primary-900 px-4 pb-1 text-3xl border-[#E5BF00]'><span>-</span></button>
                                :
                                <button onClick={()=>{
                                    setFaq4(true)
                                    expand('#faq4', '#faq4Heading', '#faq4Desc')}}
                                    className='border bg-website-500 text-primary-900 px-3 pb-1 text-3xl border-[#E5BF00]'><span>+</span></button>}
                            </div>
                        </div>
                        <div id='faq4Desc' className={`border p-7 pt-0 text-base border-[#E5BF00] border-t-0 ${Faq4 ? 'block' : 'hidden'}`}>
                            <div className='flex justify-start pt-2'>
                                <div className="h-1 md:w-[40%] w-[60%] flex justify-start items-center relative " style={{ background: ' transparent radial-gradient(closest-side at 50% 50%, #E5BF00 0%, #000000 100%) 0% 0% no-repeat padding-box', opacity: 1 }} ><img className='absolute left-6 h-[6px]' src={footerLineGlow} /></div>
                            </div>
                            <p className='pt-7'>There are several Elchai Car Club NFT variations: The Elchai Car Club Card NFTs that grants members access to supercars and luxury rides. The quantity to be minted will be limited as the NFT tier rises for the Elchai VIP Capsule NFT, Luxury Capsule NFT, and Penthouse NFTs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faqs