import React from 'react'
import ellipse from 'images/Ellipse 16.png'
import './HowToBuyNFT.scss'
import MainTitleHeader from 'components/mainHeaderTitle'
const desc = [
    "Invest in your access to a new world. The Elchai group NFT runs on Ethereum blockchain.",
]
const HowToBuyNFT = () => {
    return (
        <div className='flex flex-col justify-center mt-48'>
            <div className='mb-3'>
                <MainTitleHeader title={'How to Buy NFT'} desc={desc} position={'left'}/>
            </div>

            <div className='flex px-5  flex-wrap gap-16 justify-center'>

                <div className='content_card_buyNft_div w-[90%] max-w-[330px] sm:w-[330px] mt-12'>
                    <div id='faq_div_1' className='buyNFT2 content_card_buyNft h-[240px] sm:h-[200px]'>
                        <span className="top-left-angle"></span>
                        <div className='w-11 h-2 mt-3 bg-primary-900 rounded'></div>
                        <p className='font-strasua mt-4 text-xl'>FUEL UP YOUR ETH WALLET</p>
                        <p className='text-base mt-5'>Ensure you have enough ETH tokens in your crypto wallet.</p>
                    </div>
                </div>
                <div className='content_card_buyNft_div w-[90%] max-w-[330px] sm:w-[330px] mt-12'>
                    <div id='faq_div_2' className='buyNFT2 content_card_buyNft h-[240px] sm:h-[200px]'>
                        <span className="top-left-angle"></span>
                        <div className='w-11 h-2 mt-3 bg-primary-900 rounded'></div>
                        <p className='font-strasua mt-4 text-xl'>CONNECT WALLET</p>
                        <p className='text-base mt-5'>Use Trust Wallet, Metamask, or other wallet to connect on OpenSea.</p>
                    </div>
                </div>

                <div className='content_card_buyNft_div w-[90%] max-w-[330px] sm:w-[330px] mt-12'>
                    <div id='faq_div_3' className='buyNFT2 content_card_buyNft h-[240px] sm:h-[200px]'>
                        <span className="top-left-angle"></span>
                        <div className='w-11 h-2 mt-3 bg-primary-900 rounded'></div>
                        <p className='font-strasua mt-4 text-xl'>SELECT & BUY NFT</p>
                        <p className='text-base mt-5'>Select your NFT, buy and approve transactions. It’s that easy!</p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default HowToBuyNFT