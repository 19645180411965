import React,{useState} from 'react';
import { Link, } from "react-router-dom";
import cardNFT from 'video/CardNFT.mp4'
import Capsule01NFT from 'video/Capsule01NFT.mp4'
import Capsule02NFT from 'video/Capsule02NFT.mp4'
import PenthouseNFT from 'video/PenthouseNFT.mp4'
import elchaiRotateVid from 'video/elchaiBannerVideo.mp4'
import Capsule01NFT_img from 'images/Capsule01NFT.png'
import Capsule02NFT_img from 'images/Capsule02NFT.png'
import PenthouseNFT_img from 'images/PenthouseNFT.png'
import dollar from '../../images/Dollar.png'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import MainTitleHeader from 'components/mainHeaderTitle'
import free from 'images/free@2x.png'
import './OurCollection.scss'
import Counter from './Counter';

const Card = ({ text, video, price = "Free", link, image }) => {
    // sm:w xl:w-[280px]
    const [isCounterFin, setisCounterFin] = useState(false)
    const isCounterDone = () => {
        setisCounterFin(true)
    }
    return (
        <div id='our_collection'  className='w-full xl:w-[280px]  flex flex-col justify-center gap-[17px]'>
            <div className='content_card_our_collection p-4 min-h-[309px]'>
                <span className="top-left-angle"></span>
                <Link to={`${link ? link : ''}`}>
                    <div className='relative'>
                        {video ? <video className="top-left-angle-1 cursor-pointer" playsInline muted loop autoPlay >
                            <source src={video} type="video/mp4" height={400} />
                            Your browser does not support the video tag.
                        </video> :
                            <video className="top-left-angle-1 cursor-pointer" playsInline muted loop autoPlay >
                                <source src={elchaiRotateVid} type="video/mp4" height={400} />
                                Your browser does not support the video tag.
                            </video>}
                    </div>
                </Link>

            </div>

            <div className='flex flex-col'>
                <Link to={`${link ? link : ''}`}>
                    <h3 className='text-[#E5BF00] mb-[16px] text-xl hover:underline  cursor-pointer'>{text}</h3>
                </Link>
                <div className='flex justify-between'>
                    <p className='text-[#B5B5B5]'>{price}</p>
                    <div className='flex justify-center items-center'>

                        {/* <img src={dollar} alt="dollar" /> */}
                        {/* <p className='pl-1.5'>{price}</p> */}
                    </div>
                </div>
               { !isCounterFin && video ? <div>
                    <Counter  isCounterDone={isCounterDone}/>
                </div>:null}
            </div>
        </div>
    )
}
const smallCardArray = [
    {
        text: "Card NFT",
        price: "Free Mint",
        video: cardNFT,
        link: '/nft/card-nft',
        image: ""
    },
    {
        text: "VIP Capsule",
        price: "Comming Soon",
        video: "",
        link: "#",
        image: Capsule01NFT_img,
        // link: '/nft/vip-capsule'
    },
    {
        text: "Luxury Capsule",
        price: "Comming Soon",
        video: "",
        link: "#",
        image: Capsule02NFT_img,
        // link: '/nft/luxury-capsule'
    },
    {
        text: "Penthouse Capsule",
        price: "Comming Soon",
        video: "",
        link: "#",
        image: PenthouseNFT_img,
        // link: '/nft/penthouse-capsule'
    },
]
const OurCollection = () => {
    return (
        <div className='container mt-48 pb-1 our-collection-mb'>
            <div className=''>
                <MainTitleHeader title={'Our collections'} />
            </div>
            {/* <div className='flex items-center xl:flex-row flex-col gap-[20px] lg:gap-[41px] mt-40'> */}
            <div className='items-baseline xl:flex flex sm:grid grid-cols-2 lg:grid-cols-3 flex-col xl:flex-row justify-start gap-10 mt-32 px-5'>
                {smallCardArray.map((card, index) => {
                    return <Card key={`nft${index}`} text={card.text} price={card.price} video={card.video} link={card.link} image={card.image} />
                })}
                {/* </div> */}

            </div>
            <div className='flex justify-center w-full mt-14 pb-5'>
                <Link to='/nft/card-nft'>
                    <ButtonPrimary className="flex py-3 justify-center text-primary-900 items-center font-strasua sm:text-base text-sm sm:w-[140px] ">Mint Nft</ButtonPrimary >
                </Link>
            </div>
            <div className='xl:mt-24 mt-5'>
            </div>
        </div>
    )
}

export default OurCollection;

let linkT = (linkTxt) => linkTxt.toLowerCase().split(" ").join("-")
