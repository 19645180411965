
import React from 'react'
import ellipse from 'images/bgtitle.png'
import right from 'images/right.png'
import left from 'images/left.png'
const MainTitleHeader = ({title, desc, position}) => {
    return (
        <div className='relative w-full text-center mb-7 sm:mb-10 md:mb-20' >
            <div className='absolute xl:top-[-4rem] top-[-7rem] left-[1rem] lg:left-3 2xl:left-16 h-[350px] w-full max-w-[90vw] mx-auto' style={{ backgroundImage: `url(${ellipse})`, backgroundSize: 'cover', backgroundPosition: "center", backgroundRepeat: "no-repeat", filter: 'blur(50px)' }} >
            </div>
            {position && position=='right' ? 
            <div className='w-full flex absolute justify-end top-[-200px] md:top-[-300px] overflow-hidden'><img src={right} className=' right-[-100px] md:right-[-150px] relative'></img></div>
            // <div className='absolute top-[-300px] left-[100px] w-full max-w-[90vw] h-[390px]' style={{ backgroundImage: `url(${right})`, backgroundSize: 'contain', backgroundPosition: 'right', backgroundRepeat: "no-repeat",}} >
            // </div>
            :
            position=='left' ? 
            <div className='w-full flex absolute justify-start top-[-200px] md:top-[-300px] overflow-hidden'><img src={left} className=' left-[-100px] md:left-[-150px] relative'></img></div>
            // <div className='absolute top-[-300px] left-[-100px] w-full max-w-[90vw] h-[390px]' style={{ backgroundImage: `url(${left})`, backgroundSize: 'contain', backgroundPosition: 'left', backgroundRepeat: "no-repeat",}} >
            // </div>
            :''}
            <h1 className='xl:text-8xl sm:text-6xl lg:text-7xl text-4xl px-4 opacity-25 text-[#FFCA65] capitalize'  >{title}</h1>
            <h3 className='xl:text-4xl sm:text-2xl text-xl lg:text-3xl px-4 relative bottom-5 sm:bottom-7 xl:bottom-10 text-[#E5BF00] capitalize'>{title}</h3>
            {desc ? desc.map((line)=>
            <p className='text-base mb-1 px-4'>{line}</p>)
            :   ''}
        </div>
    )
}

export default MainTitleHeader;