import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import OurCollection from "components/Collections/OurCollection";
import ElchaiCarClub from "components/ElchaiCarClub/ElchaiCarClub";
import NFTCollection from "components/NFTCollection/NFTCollection";
import ListCollection from "components/ListCollections/ListCollection";
import Faqs from "components/FAQ/Faqs";
import RoadMapComp from "components/roadmap/roadMap";
import { Link, useParams } from "react-router-dom";
import HowToBuyNFT from "components/HowToBuyNFT/HowToBuyNFT";


const HomePage = () => {
    let param = useParams();
    console.log("parmas : ", param)
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (

        <div className=" nc-fill-container">
            <Helmet>
                <title>Elchai Car Club</title>
            </Helmet>
            <ElchaiCarClub/>
           
            <OurCollection/>
            <NFTCollection />
            {/* <ListCollection/> */}
            <HowToBuyNFT />
            <RoadMapComp />
            <Faqs />
            <div className="text-center text-[#FFCA65] text-base mt-12 font-strasua">   <Link to={`/terms-and-conditions`}> Terms and conditions</Link> </div>
        </div>
    );
}

export default HomePage;
