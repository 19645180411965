import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/utility.png";
import logoLightImg from "images/utility.png";
import logo from "images/icons/logo.png";
const Logo = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo lg:-ml-[21px] inline-block text-primary-6000 ${className}`}
    >
      {/* <h1 className="text-2xl cursor-pointer font-strasua text-primary-900 uppercase">BEC Token</h1> */}
      <img className="h-28" src={logo} />
    </Link>
  );
};

export default Logo;
