import Button from "shared/Button/Button";
import React from "react";
import InvertCredButtonPrimary from "./invertCredButtonPrimary";

const InvertButtonPrimary = ({
  className = "",
  ...args
}) => {
  return (
    <InvertCredButtonPrimary
      className={`disabled:bg-opacity-70 ${className}`}
      {...args}
    />
  );
};

export default InvertButtonPrimary;