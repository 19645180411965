import React, { Fragment, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Logo from "shared/Logo/Logo";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { NAVIGATION_DEMO_2 } from "data/navigation";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { userSlice } from "app/user/user";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import ConnectWalletModal from "components/ConnectWalletModal/ConnectWalletModal";


const NavMobile = ({
  data = NAVIGATION_DEMO_2,
  onClickClose,
}) => {
  const {user} = useSelector(state=>state)
  const history = useHistory()
  const dispatch = useDispatch()

  const [isShow, setIsShow] = useState(false)
  const { sidebarActiveMenuId } = useSelector(state => state.menu)
  const { connector: activeConnector, isConnected, address, isDisconnected, status } = useAccount()


  const toggleModal = () => {
    console.log("here")
    let t = !isShow;
    setIsShow(!isShow)
  }


  const _renderMenuChild = (item) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              exact
              strict
              to={{
                pathname: i.href || undefined,
              }}
              className="flex px-4 py-2.5 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-[2px]"
              activeClassName="text-secondary"
            >
              <span
                className={!i.children ? "block w-full" : ""}
                onClick={onClickClose}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="block flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-neutral-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item, index) => {
    if(item.name == "Home" || item.name == "Elchai Ecosystem" || item.name=="Token" || item.name == "Light Paper" || item.name =="Our Team" || "" ){

      return  (
      <a  
      onClick={onClickClose}       
        className="flex text-white w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
      href={`${item.href}`}>{item.name} {item.type && (
        <ChevronDownIcon
          className="ml-1 -mr-1 h-4 w-4 text-neutral-400"
          aria-hidden="true"
        />
      )}</a> )
    }
    // return (
    //   <Disclosure
    //     key={item.id}
    //     as="li"
    //     className="text-neutral-900 dark:text-white"
    //   >
    //     <NavLink
    //       exact
    //       strict
    //       className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
    //       to={{
    //         pathname: item.href || undefined,
    //       }}
    //       activeClassName="text-secondary"
    //     >
    //       <span
    //         className={!item.children ? "block w-full" : ""}
    //         onClick={onClickClose}
    //       >
    //         {item.name}
    //       </span>
    //       {item.children && (
    //         <span
    //           className="block flex-grow"
    //           onClick={(e) => e.preventDefault()}
    //         >
    //           <Disclosure.Button
    //             as="span"
    //             className="flex justify-end flex-grow"
    //           >
    //             <ChevronDownIcon
    //               className="ml-2 h-4 w-4 text-neutral-500"
    //               aria-hidden="true"
    //             />
    //           </Disclosure.Button>
    //         </span>
    //       )}
    //     </NavLink>
    //     {item.children && (
    //       <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
    //     )}
    //   </Disclosure>
    // );
  };



  const logout = () => {
    dispatch(userSlice.actions.removeUser({}))
    history.replace('/')
  }

  

  return (
    <div className="relative overflow-y-auto w-[254px] lg:w-[400px] max-w-[300px] h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>
      <div className='logo-head mt-8'>
        <h1 className='text-2xl whitespace-nowrap cursor-pointer font-strasua text-primary-900 uppercase text-center'>BEC Token</h1>
      </div>

      <div className="bg-website-480 h-[1px] w-[80%] m-auto mt-4 mb-6"></div>
      <div className="px-7 py-4 text-base text-white">
        <NavLink activeId="dashboard" to="/dashboard" className="flex flex-row items-center">
            <span className='w-5 h-5'>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g id="Group_2514" data-name="Group 2514" transform="translate(-26.974 -100.083)">
                  <g id="Group_2512" data-name="Group 2512" transform="translate(26.974 99.701)">
                    <rect id="Rectangle_166" data-name="Rectangle 166" width="7" height="9" rx="1" transform="translate(0 0.383)" fill={sidebarActiveMenuId == "dashboard" ? "#e5bf00" : "#737373"} />
                    <rect id="Rectangle_168" data-name="Rectangle 168" width="7" height="5" rx="1" transform="translate(0 11.383)" fill={sidebarActiveMenuId == "dashboard" ? "#e5bf00" : "#737373"} />
                  </g>
                  <g id="Group_2513" data-name="Group 2513" transform="translate(42.974 115.466) rotate(180)">
                    <rect id="Rectangle_166-2" data-name="Rectangle 166" width="7" height="9" rx="1" transform="translate(0 -0.617)" fill={sidebarActiveMenuId == "dashboard" ? "#e5bf00" : "#737373"} />
                    <rect id="Rectangle_168-2" data-name="Rectangle 168" width="7" height="5" rx="1" transform="translate(0 10.383)" fill={sidebarActiveMenuId == "dashboard" ? "#e5bf00" : "#737373"} />
                  </g>
                </g>
              </svg>
            </span>
            <span className='ml-4'>Dashboard</span>
          </NavLink>
      </div>
      <div className="px-7 py-4 text-base text-white">
        <NavLink activeId="buy-tokens" to="/dashboard/buy-tokens" className="flex flex-row items-center">
          <span className='w-5 h-5'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <g id="Group_2515" data-name="Group 2515" transform="translate(0 0)">
                <path id="Path_2321" data-name="Path 2321" d="M340.641-127.626c-.353.757-.715,1.511-1.075,2.266-.729,1.533-1.465,3.064-2.184,4.6a.738.738,0,0,1-.757.467c-2.828,0-5.656.01-8.484.016h-.365a5.367,5.367,0,0,0,.085.872,1.1,1.1,0,0,0,1.149.759q3.832,0,7.665,0c.378,0,.758,0,1.136,0a.57.57,0,0,1,.634.542.564.564,0,0,1-.635.539c-2.952,0-5.9.01-8.855,0a2.193,2.193,0,0,1-2.288-2.262q0-5.767,0-11.535v-.322h-.76c-.679,0-1.357,0-2.035,0a.557.557,0,0,1-.587-.535.546.546,0,0,1,.576-.541c.951-.006,1.9-.012,2.854,0a1.069,1.069,0,0,1,1.078,1.121c.009.951,0,1.9,0,2.852v.335h12.073c.079,0,.158,0,.238,0A.547.547,0,0,1,340.641-127.626Z" transform="translate(-323.285 135.54)" fill={sidebarActiveMenuId == "buy-tokens" ? "#e5bf00" : "#737373"} />
                <path id="Path_2322" data-name="Path 2322" d="M505.418-168.474a.471.471,0,0,1-.193.326c-.575.34-1.159.667-1.751.974a.512.512,0,0,1-.407,0c-.578-.3-1.14-.628-1.712-.936a.393.393,0,0,1-.239-.394q.013-1.056,0-2.112a.386.386,0,0,1,.234-.395c.571-.293,1.135-.6,1.7-.91a.405.405,0,0,1,.441-.01q.853.472,1.719.924a.365.365,0,0,1,.225.374c-.011.361,0,.722,0,1.083S505.443-168.833,505.418-168.474Z" transform="translate(-488.557 171.996)" fill={sidebarActiveMenuId == "buy-tokens" ? "#e5bf00" : "#737373"} />
                <path id="Path_2323" data-name="Path 2323" d="M425.674-123.827c0,.3-.061.357-.355.358q-1.36,0-2.719,0-1.334,0-2.666,0c-.347,0-.386-.038-.388-.373s.006-.686,0-1.03a.375.375,0,0,1,.2-.367q1.333-.829,2.657-1.672a.335.335,0,0,1,.41,0c.89.566,1.788,1.121,2.673,1.694a.5.5,0,0,1,.186.338C425.69-124.531,425.677-124.178,425.674-123.827Z" transform="translate(-412.745 130.16)" fill={sidebarActiveMenuId == "buy-tokens" ? "#e5bf00" : "#737373"} />
                <path id="Path_2324" data-name="Path 2324" d="M407.2,98.039a1.3,1.3,0,0,1-1.309,1.284,1.283,1.283,0,0,1-1.287-1.3,1.3,1.3,0,1,1,2.6.012Z" transform="translate(-398.863 -78.323)" fill={sidebarActiveMenuId == "buy-tokens" ? "#e5bf00" : "#737373"} />
                <path id="Path_2325" data-name="Path 2325" d="M488.427,98.059a1.3,1.3,0,0,1-2.592,0,1.318,1.318,0,0,1,1.313-1.309A1.291,1.291,0,0,1,488.427,98.059Z" transform="translate(-474.357 -78.351)" fill={sidebarActiveMenuId == "buy-tokens" ? "#e5bf00" : "#737373"} />
              </g>
            </svg>
          </span>
          <span className='ml-4'>Buy Tokens</span>
        </NavLink>
      </div>
      <div className="px-7 py-4 text-base text-white">
        <NavLink activeId="my-tokens" to="/dashboard/my-tokens" className="flex flex-row items-center">
          <span className='w-5 h-5'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <g id="Group_2974" data-name="Group 2974" transform="translate(-27 -148.212)">
                <circle id="Ellipse_275" data-name="Ellipse 275" cx="10" cy="10" r="10" transform="translate(27 148.212)" fill={sidebarActiveMenuId == "my-tokens" ? "#e5bf00" : "#737373"} />
                <text id="Search..." transform="translate(33 163.212)" fill="#161616" fontSize="14" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600"><tspan x="0" y="0">B</tspan></text>
              </g>
            </svg>
          </span>
          <span className='ml-4'>My Tokens</span>
        </NavLink>
      </div>



      {/* <NavLink activeId="transaction" to="/dashboard/transaction">
        <span className='w-5 h-5'>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_2516" data-name="Group 2516" transform="translate(-31.671 -250)">
              <g id="Icon_feather-arrow-down-right" data-name="Icon feather-arrow-down-right" transform="translate(33.671 265.682) rotate(-45)">
                <path id="Path_2326" data-name="Path 2326" d="M0,0,8.935,8.935" transform="translate(0 0)" fill="none" stroke={sidebarActiveMenuId=="transaction"?"#e5bf00":"#737373"} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_2327" data-name="Path 2327" d="M4,0V4H0" transform="translate(4.938 4.938)" fill="none" stroke={sidebarActiveMenuId=="transaction"?"#e5bf00":"#737373"} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
              <g id="Icon_feather-arrow-down-right-2" data-name="Icon feather-arrow-down-right" transform="translate(46.307 258.318) rotate(135)">
                <path id="Path_2326-2" data-name="Path 2326" d="M0,0,8.935,8.935" transform="translate(0 0)" fill="none" stroke={sidebarActiveMenuId=="transaction"?"#e5bf00":"#737373"} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_2327-2" data-name="Path 2327" d="M4,0V4H0" transform="translate(4.938 4.938)" fill="none" stroke={sidebarActiveMenuId=="transaction"?"#e5bf00":"#737373"} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </g>
          </svg>
        </span>
        <span className='ml-4'>Transaction</span>
      </NavLink>
      <NavLink activeId="kyc-application" to="/dashboard/kyc-application">
          <span className='w-5 h-5'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path id="Icon_material-verified-user" data-name="Icon material-verified-user" d="M11.324,1.5,4.5,4.533V9.082a9.436,9.436,0,0,0,6.824,9.1,9.436,9.436,0,0,0,6.824-9.1V4.533ZM9.807,13.631,6.775,10.6,7.844,9.529l1.964,1.956,5-5,1.069,1.077Z" transform="translate(-4.5 -1.5)" fill={sidebarActiveMenuId=="kyc-application"?"#e5bf00":"#737373"}/>
            </svg>
          </span>
          <span className='ml-4'>KYC Application</span>
      </NavLink> */}
    </div>
  );
};

export default NavMobile;
