import React, { createContext, useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import { useSigner, useAccount } from 'wagmi';
import { CARD_NFT_CONTRACT_ABI, CARD_NFT_ADDRESS, VIP_CAPSULE_ADDRESS, LUXURY_CAPSULE_ADDRESS, PENTHOUSE_CAPSULE_ADDRESS } from "_blockchain/contractsAbi.js";
const WalletContext = createContext();
export const useWalletContext = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
  const [contextData, setContextData] = useState({ contractInstance: null });

  const { address, isConnected } = useAccount();
  const { data: wagmiSigner } = useSigner();

  const getCardNftContractInstance = async () => {
    if (!isConnected && !wagmiSigner) return null
    const tb = new ethers.Contract(CARD_NFT_ADDRESS, CARD_NFT_CONTRACT_ABI, wagmiSigner);
    setContextData({ ...contextData, contractInstance: tb })
    return tb
  }

  //  const getVipCapsuleContractInstance = async () => {
  //   if(!isConnected && !wagmiSigner) return null
  //    const tb = new ethers.Contract(VIP_CAPSULE_ADDRESS, NFT_CONTRACT_ABI, wagmiSigner);
  //    setContextData({...contextData,contractInstance:tb})
  //    return tb
  //  }

  //  const getLuxuryCapsuleContractInstance = async () => {
  //   if(!isConnected && !wagmiSigner) return null
  //    const tb = new ethers.Contract(LUXURY_CAPSULE_ADDRESS, NFT_CONTRACT_ABI, wagmiSigner);
  //    setContextData({...contextData,contractInstance:tb})
  //    return tb
  //  }

  //  const getPenthouseCapsuleContractInstance = async () => {
  //   if(!isConnected && !wagmiSigner) return null
  //    const tb = new ethers.Contract(PENTHOUSE_CAPSULE_ADDRESS, NFT_CONTRACT_ABI, wagmiSigner);
  //    setContextData({...contextData,contractInstance:tb})
  //    return tb
  //  }

  const isMinitingSoldOut = async () => {
    try {
      const tb = new ethers.Contract(CARD_NFT_ADDRESS, CARD_NFT_CONTRACT_ABI, wagmiSigner/* ethers.getDefaultProvider('polygonMumbai') */)
      const isSoldOut = await tb.nftsAvailabelForFreeMint();
      return isSoldOut.toString();
    } catch (error) {
      console.log("ERROR");
      return 0
    }
  }


  const getConnectAddress = async () => {
    if (!isConnected) return null
    return address
  }

  return (
    <WalletContext.Provider value={{
      getConnectAddress,
      getCardNftContractInstance,
      isMinitingSoldOut
     /*  getVipCapsuleContractInstance,
      getLuxuryCapsuleContractInstance,
      getPenthouseCapsuleContractInstance */}}>
      {children}
    </WalletContext.Provider>
  );
};
