import ncNanoId from "utils/ncNanoId";
import CollectionIcon from "images/icons/collections.png"
import HomeIcon from "images/icons/home.png"
import MarketplaceIcon from "images/icons/marketplace.png"

export const NAVIGATION_DEMO_2 = [
  {
    id: ncNanoId(),
    href: "/",
    icon: HomeIcon,
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/#about-us",
    icon: CollectionIcon,
    name: "About Us",
  },
  {
    id: ncNanoId(),
    href: "/nft/card-nft",
    icon: MarketplaceIcon,
    name: "Mint",
  },
  {
    id: ncNanoId(),
    href: "/#nc-roadmap",
    icon: MarketplaceIcon,
    name: "RoadMap",
  },
  // {
  //   id: ncNanoId(),
  //   href: "/team",
  //   icon: MarketplaceIcon,
  //   name: "Team",
  // },
  {
    id: ncNanoId(),
    href: "/#faq",
    icon: MarketplaceIcon,
    name: "Faq",
  },
  // {
  //   id: ncNanoId(),
  //   href: "#our-team",
  //   icon: MarketplaceIcon,
  //   name: "Our Team",
  // },
];

/* 
Home
Elchai Ecosystem
Token
Light Paper
Our Team
Language
Login
*/