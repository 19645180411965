import React from "react";
import MainNav2Logged from "./MainNav2Logged";

const HeaderLogged = () => {
  return (
    <div className="nc-HeaderLogged bg-website-220 relative w-full z-40 ">
      {/* NAV */}
      <MainNav2Logged />
    </div>
  );
};

export default HeaderLogged;
