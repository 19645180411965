import React from 'react'
import ellipse from 'images/Ellipse 24.svg'
import NFTImg from 'images/Mask Group 36.png'
import Mask from 'images/Mask.png'
import NFTCollectionVideo from 'video/NFTCollection.mp4'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import { Link, useHistory } from 'react-router-dom'
import "../../components/NFTCollection/nftcollection.scss";
// import './collection.scss'

import MainTitleHeader from 'components/mainHeaderTitle'
const NFTCollection = () => {
    const history = useHistory()
    return (
        <div id='about-us' className=' mt-56 mb-72'>
            <div className='mb-24'>
                <MainTitleHeader title={'About the Club'} position={'left'}/>
            </div>
            <div className='container flex flex-wrap justify-between h-auto    px-5  gap-[60px] sm:gap-0 '>
                <div className='md:w-[48%] w-full  h-[500px] sm:h-[768px]' >
                    <div className='relative'>
                        <div className='absolute w-full h-auto sm:h-[768px] md:h-[694px] xl:h-[768px]  -left-[73px]' style={{ backgroundPosition: ' right 0 bottom 45%', backgroundImage: `url(${Mask})`, backgroundSize: 'cover', backgroundPosition: "center", backgroundRepeat: "no-repeat", }}></div>
                        {/* TO DO nft_clip left corner clip with border */}
                        <div className='absolute p-4 border border-[#E5BF00] sm:top-[94px] lg:top-[136px] xl:top-[94px] 2xl:top-[82px]' style={{ backgroundColor: 'rgb(255 202 101 / 10%)', zIndex: '1' }}>
                            {/* <div className='cut_left_corner_nft'></div> */}
                                {/* TO DO nft_clip left corner clip with border */}
                                <video className="nft_clip cursor-pointer" playsInline muted loop autoPlay >
                                    <source src={NFTCollectionVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                        </div>
                    </div>

                </div>
                <div className='md:w-[48%] md:mt-0 mt-10 w-full h-auto'>
                    <div className='relative mt-10 md:mt-0 xl:mt-20'>
                        <div className=''>
                            <div className='font-strasua text-2xl'>ELCHAI CAR CLUB WHERE THE METAVERSE AND REALITY UNITES</div>
                            <div className=' text-base md:mt-8 mt-4'>The Elchai Car Club is bringing the future of car and luxury ownership in the metaverse. And we are starting the initiative in the city of Dubai, UAE.</div>
                            <div className=' text-base mt-4'>We’re launching the Elchai Car Club NFTs in our mission to share our passion for supercars to the bigger community. It is the first-of-its-kind luxury NFT in Dubai that offers its community both the digital and physical experience.</div>
                            <div className='font-strasua text-2xl mt-8'>Own it in the metaverse. Use it in real life.</div>
                            <div className=' text-base md:mt-8 mt-4'>NFT holders will be able to drive 50+ exclusive limited-edition and custom-made hyper cars. Moreover, will have access to three high-end real estate at The Club to be constructed in Dubai. Elchai Club aims to establish 100 clubs around the world by 2027.</div>
                            <div className=' text-base mt-4'>Join the club, own the NFT, and experience luxury like never before!</div>
                            <div className='flex justify-center md:justify-start xl:mt-24 mt-5'>
                                <a href='https://discord.gg/zNVwfuTExT' target='_blank'>
                                    <ButtonPrimary className="flex sm:px-8 px-5 py-3 justify-center text-primary-900 items-center font-strasua sm:text-base text-sm sm:w-[230px] ">Connect To Discord</ButtonPrimary>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default NFTCollection